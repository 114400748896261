<template>
  <div class="HomeView">
    <LandingComp />
  </div>
</template>

<script>
import LandingComp from '@/components/LandingComp.vue'

export default {
  name: 'HomeView',
  components: {
    LandingComp
  }
}
</script>
