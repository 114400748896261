<template>
    <nav class="navbar" role="navigation" aria-label="main navigation" style="background-color: #F8F8F8;margin-bottom:20px;border-bottom: 1px;border-bottom-style:solid;border-bottom-color: #E8E8E8;">
    <div class="navbar-brand">
      <router-link  class="navbar-item" to="/">
        <img src="../assets/IconOnly_Transparent_NoBuffer.png" width="28" height="28">
      </router-link>
  
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <router-link class="navbar-item" to="/schedule" v-if="isLoggedIn">Schedule</router-link>      
        <router-link class="navbar-item" to="/assign-visits" v-if="isLoggedIn & isSupplier">Assign Visits</router-link>      
        <router-link class="navbar-item" to="/about">About</router-link>
      </div>
  
      <div class="navbar-end">
          <span v-if="isLoggedIn" class="navbar-item pr-6" style="font-weight: bold;">{{ getAccountName() }}</span>
          <router-link v-if="!isLoggedIn" class="navbar-item" to="/login"> Log in </router-link >
          <router-link v-if="isLoggedIn" class="navbar-item" to="/settings"> Settings </router-link >
          <router-link  v-if="isLoggedIn" class="navbar-item" to="/logout" @click="logOut()"> Log out </router-link>
      </div>
    </div>
  </nav>
  </template>

<script>
import { userStore } from '../store/UserStore.js'

export default {
    name: 'MenuBar',
    data() {
      return {
        store: userStore,
      }
    },
    mounted() {
      console.log(this.store);
      if ((Date.now()/1000) > this.store.state.user.token_expiry) {
        userStore.logout();
      }
    },
    computed: {
      isLoggedIn() {
        return this.store.state.isLoggedIn;
      },
      isSupplier() {
        let account = this.store.state.user.account;
        if (account) {
          return account.type === 'supplier';
        }
        return false;
      }
    },
    methods: {
      logOut() {
          this.store.logout();
          this.$router.push('/');
      },
      getAccountName() {
          try {
              return this.store.state.user.account.name;
          }
          catch (error) {
              return '';
          }
      },
    } 
}
</script>

<style scoped>
nav { 
    background-color: white;
    margin: 0px;
    margin: 0px;
    padding:  0px;
}
</style>

