<template>
  <div class="rowHeaders" :style="'height:'+((visits.length*hour_height)+80)+'px;width:'+hour_width+'px;'">
    <div class="rowHeader">&nbsp;</div>
    <div class="rowHeader" v-for="(visitGroup, rowHeader, index) in visits" :key="index">
      <a :href="'/schedule/'+group_type+ '/'+visitGroup.group.id+'/' ">{{ visitGroup.group.name }}</a>
      </div>
  </div>
  
    
</template>

<script>
export default {
  name: 'SchedulerWideRowHeaders',
  props: {
    visits: {
      type: Object,
      required: true,
    },
    group_type: {
      type: String,
      required: true,
    },
    hour_height: {
      type: Number,
      required: true,
    },
    hour_width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  
  
}
</script>

<style scoped>
.rowHeaders {
  display: grid;
  grid-template-columns: 120px;
  grid-template-rows: repeat(auto-fill, 80px);
  grid-auto-flow: row;
  grid-auto-columns: 120px;
  grid-auto-rows: 80px;
  width: 120px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}

.rowHeader {
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  border-right: 0px;
  width: 120px;
  height: 80px;
  
}

</style>