<template>
  <div class="rows" :style="'height:'+((visits.length*hour_height)+80)+'px;width:'+hour_width*24+'px;'">
    <div class="headerRow"> 
      <div v-for="hour, index in hours" :key="hour" class="hourCell" :style="'left:'+((index*120))+'px;'"></div>
      <div v-for="hour, index in hours" :key="hour" class="hourTitle" :style="'left:'+((index*120)+60)+'px;'"><span style="background-color: #ffffff;padding-top:4px;padding-bottom:4px;">{{ hour }}</span></div>
    </div>
    <div class="row" v-for="(visitList, index) in visits" :key="index">
      <SchedulerWideRow :visitList="visitList" :hour_height="hour_height" :hour_width="hour_width"/>
      </div>
  </div>
    
</template>

<script>
import SchedulerWideRow from './SchedulerWideRow.vue';

export default {
  name: 'SchedulerWideBody',
  components: {
    SchedulerWideRow
  },
  props: {
    visits: {
      type: Object,
      required: true,
    },
    hour_height: {
      type: Number,
      required: true,
    },
    hour_width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      hours: ['1:00','2:00','3:00','4:00','5:00','6:00','7:00','8:00','9:00','10:00','11:00','12:00',
              '13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'],
    }
  },
  
}
</script>

<style scoped>
.rows {
  border: 1px solid #f8f8f8;
  width: 2800px;
}
.row{
  position: relative;
  height: 80px;
  text-align: left;
}
.headerRow{
  position: relative;
  height: 80px;
  text-align: left;
}
.hourCell {
  position: absolute;
  height: 80px;
  top:0px;
  width:120px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}
.hourTitle {
  position: absolute;
  height: 30px;
  top:45px;
  width:120px;
  text-align: center;
  vertical-align: bottom;
  font-weight: bold;
}

.rowCell {
  position: absolute;
  height: 80px;
  top:0px;
}


</style>