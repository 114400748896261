<template>
    <footer class="footer is-small">
      <div class="content has-text-centered">
        <p  class="small">
          Suple is a bridge between Care Providers and Suppliers (Agencies), securely passing on requests for visits and passing back details of the visits as they are delivered.
        </p>
        <p  class="small">
          <!-- <router-link to="/termsandconditions">terms and conditions</router-link> |  -->
          <!-- <router-link to="/privacypolicy">privacy policy</router-link> |  -->
          <router-link to="/about/">about</router-link> | 
          <router-link to="/contact/">contact</router-link>
        </p>
      </div>
    </footer>
  </template>