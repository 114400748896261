import { base_url } from './Request'


export const unassigned_visits_request = async () => {
    try {
        const response = await fetch(base_url + 'visit/unassigned/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        });
        return response.json();
    }
    catch (error) {
        return {'meta': {'error_code': 1, 'message': 'Unexpected error'}, 'data': []}
    }
}

export const employee_finder_request = async (visit_id) => {
    try {
        const response = await fetch(base_url + 'visit/'+visit_id+'/employee_finder/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        });
        return response.json();
    }
    catch (error) {
        return {'meta': {'error_code': 1, 'message': 'Unexpected error'}, 'data': []}
    }
}

// '/visit/<int:visit_id>/employee_assign/<int:user_id>/'

export const visit_employee_assign_request = async (visit_id, user_id) => {
    try {
        const response = await fetch(base_url + 'visit/' + visit_id + '/employee_assign/' + user_id + '/', 
            {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        });
        return response.json();
    }
    catch (error) {
        return {'meta': {'error_code': 1, 'message': 'Unexpected error'}, 'data': []}
    }
}

export const visit_clock_request = async (visit_id, clock_package) => {
    try {
        const response = await fetch(base_url + 'visit/' + visit_id + '/clock/',
            {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),  
            },
            body: JSON.stringify(clock_package),
        });
        return response.json();
    }
    catch (error) {
        return {'meta': {'error_code': 1, 'message': 'Unexpected error'}, 'data': []}
    }
}

export const visit_clock_auth_request = async (visit_id, eventPackage) => {
    try {
        const response = await fetch(base_url + 'visit/' + visit_id + '/auth_clock/',
            {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),  
            },
            body: JSON.stringify(eventPackage),
        });
        return response.json();
    }
    catch (error) {
        return {'meta': {'error_code': 1, 'message': 'Unexpected error'}, 'data': []}
    }
}

export const visit_schedule_request = async (filter_by, filter_id, group_by) => {
    try {
        const response = await fetch(base_url + 'visit/schedule/' + filter_by + '/'+filter_id+'/'+group_by+'/',
            {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),  
            }
        });
        return response.json();
    }
    catch (error) {
        return {'meta': {'error_code': 1, 'message': 'Unexpected error'}, 'data': []}
    }
}
