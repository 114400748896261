import { reactive } from 'vue'
import VueJwtDecode from 'vue-jwt-decode'

export const userStore = reactive({
    state: {
        user: {},
        isLoggedIn: false,
        token: null,
    },
    loadState() {
        if (this.state.token == null) {
            const token = localStorage.getItem('token');
            if (token) {
                this.state.token = token;
                this.state.user = VueJwtDecode.decode(token);
                this.state.isLoggedIn = true;
            }
        }
    },
    login(username, password, jwt) {
        if (username.length > 0 && password.length > 0) {
            this.state.isLoggedIn = true;
            this.state.token = jwt;
            localStorage.setItem('token', jwt);
            this.state.user = VueJwtDecode.decode(jwt);
        }else{
            this.state.user.isLoggedIn = false;
        }
    },
    logout() {
        localStorage.removeItem('token');
        this.state.isLoggedIn = false;
        this.state.token = null;
        this.state.user = {
            id: 0,
            username: '',
            email: '',
            token: '',
            isLoggedIn: false,
        };
    },
    
})


