<template>
  <ScheduleWideBox v-if="!loading" :filter_by="filter_by" :filter_value="filter_value" :group_by="group_by" :group_range_start="group_range_start" :group_range_end="group_range_end" />
</template>

<script>
import ScheduleWideBox from '@/components/SchedulerWideBox.vue'
import { useRoute } from 'vue-router'

export default {
  name: 'ScheduleView',
  components: {
    ScheduleWideBox
  },
  data() {
    return {
      loading: true,
      filter_by: 'day',
      filter_value: new Date().toISOString().split('T')[0],
      group_by: '',
    }
  },  
  mounted() {
    const route = useRoute()
    if (route.params.filter_by){
      this.filter_by = route.params.filter_by
    }
    if (route.params.filter_value){
      this.filter_value = route.params.filter_value
    } 
    if (route.params.group_by){
      this.group_by = route.params.group_by
    } else {
      if (this.filter_by == 'day') {
        this.group_by = 'employee'
      } else {
        this.group_by = 'day'
      }

    }
    if (route.params.group_range_start){
      this.group_range_start = route.params.group_range_start
    } 
    if (route.params.group_range_end){
      this.group_range_end = route.params.v
    } 
    this.loading = false
  },

}
</script>
