<template>
  <div class="LandingComp">
    
    <h1>Suple</h1>
    <p class="pb-3">We are building the bridge between providers and agencies/suppliers. We are hoping to help you</p>
    
    <div class="has-text-left" style="width:500px;margin-right: auto;margin-left: auto;">
      <ol type="1">
        <li>Automate sharing visits from providers to suppliers</li>
        <li>Share real time visit attendance for accurate HCP, NDIS etc balances </li>
        <li>Share visit notes from suppliers back to providers</li>
        <li>Cost of visits at the time of scheduling </li>
        <li>Automated electronic invoicing on visit completion</li>
      </ol>
    </div>
    
    <p class="pt-3">If you are interested in being a part of our journey, please <router-link to="/contact/">contact us</router-link>.</p>
  </div>
</template>

<script>
export default {
  name: 'LandingComp',
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin-top: 0px;
  font-size: 240px;
}

</style>
