<template>
  <div class="row">
    <div v-for="hour,index in hours" :key="index" class="rowCellBackground" :style="'left:'+(120*index)+'px;'"></div>
  </div>
  <div v-for="visit,index in activeVisitList.visits" :key="index" class="visit" :style="'left:'+(visit.start_tm*2)+'px;width:'+(visit.duration_mins*2)+'px;'" @click="openModal(visit.id);">
      <div>{{ visit.service_type }}</div>
      <div> </div>
      
    </div>
  <div  v-for="visit,index in activeVisitList.visits" :key="index" :id="'visit-'+visit.id+'-modal'" class="modal">
        <div class="modal-background" @click="closeModal(visit.id)"></div>
        <div class="modal-content">
          <div class="box">
            <div class="columns">
              <div class="column"><div class="title">Visit {{ visit.id }}</div></div>
              <div class="column has-text-right">    
                </div>
            </div>
            
            <table>
                <tr>
                  <td style="width: 100px;"><label class="label">Visit Type</label></td>
                  <td>{{ visit.service_type }}</td>
                </tr>
                <tr>
                  <td style="width: 100px;"><label class="label">Date Time</label></td>
                  <td> {{ visit.start_dt_tm.substring(0,16) }} - {{ visit.end_dt_tm.substring(11,16) }}, {{ visit.duration_mins }} mins</td>
                </tr>
                <tr>
                  <td style="width: 100px;"><label class="label">Employees</label></td>
                  <td><span v-for="user in visit.users" :key="user.resource.id">{{ user.resource.name }}, </span></td>
                </tr>
                <tr>
                  <td style="width: 100px;"><label class="label">Clients</label></td>
                  <td><span v-for="client in visit.clients" :key="client.resource.id">{{ client.resource.name_first }} {{ client.resource.name_last }}, </span></td>
                </tr>
                <tr>
                  <td style="width: 100px;"><label class="label">Instructions</label></td>
                  <td>{{ visit.instructions }}</td>
                </tr>
                <tr>
                  <td style="width: 100px;"><label class="label">Address</label></td>
                  <td>{{ visit.delivery_address }} <a :href="'https://maps.google.com/?q='+ visit.latitude +','+ visit.longitude" target="_blank" class="ml-4"><font-awesome-icon :icon="['fas', 'map-location-dot']" /></a></td>
                </tr>
                <tr>
                  <td style="width: 100px;"><label class="label">Source</label></td>
                  <td>{{ visit.source_account.name }}</td>
                </tr>
            </table>
            <br>
            <table class="table" style="width: 100%;">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Event Time</th>
                  <th>Location</th>
                  <th>Duration</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="event in visit.events" :key="event.id">
                  <td>{{ getClockEventDisplay(event) }}</td>
                  <td>{{ event.event_timestamp }}</td>
                  <td><a :href="'https://maps.google.com/?q='+ event.latitude +','+ event.longitude" target="_blank" class="ml-4"><font-awesome-icon :icon="['fas', 'map-location-dot']" /></a></td>
                  <td>{{ event.duration }}</td>
                  <td><font-awesome-icon :icon="['fas', 'circle-check']" style="color: #63E6BE;" v-if="event.source_external_id.length>0"/></td>
                </tr>
                <tr>
                  <td></td>
                  <td colspan="2"><input :id="visit.id+'_date'" class="input is-small" style="width:120px;" type="date" placeholder="Text input" :value="suggestClockDate(visit)"> &nbsp;
                                  <input :id="visit.id+'_time'" class="input is-small" style="width:120px;" type="time" placeholder="Text input" :value="suggestClockTime(visit)"></td>
                  <td colspan="2" class="has-text-right"> 
                    <div v-if="getClockInOut(visit)=='clock_in'"><button class="button is-small" @click="addClockEvent(visit,'clock_in');" :class="{'is-loading': event_in_progress }" :disabled="event_in_progress">Clock In</button></div>
                    <div v-if="getClockInOut(visit)=='clock_out'"><button class="button is-small" @click="addClockEvent(visit,'clock_out');" :class="{'is-loading': event_in_progress }" :disabled="event_in_progress">Clock Out</button></div>
                  </td>

                </tr>
                
              </tbody>

            </table>       
            <div class="has-text-right" style="width:100%;"><button class="button is-primary" @click="closeModal(visit.id);">Done</button></div>
          </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="closeModal(visit.id);"></button>
    </div>
</template>

<script>
import * as VisitRequests from '../requests/VisitRequests.js'

export default {
  name: 'SchedulerWideRow',
  props: {
    visitList: {
      type: Object,
      required: true,
    },
    hour_height: {
      type: Number,
      required: true,
    },
    hour_width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      event_in_progress: false,
      popup_error: "",
      hours: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
      activeVisitList: {},
      
    }
  },
  mounted() {
    this.activeVisitList = this.visitList;
    // Watch for changes to the visits prop
    this.$watch('visitList', function () {
      this.activeVisitList = this.visitList;
    });
    window.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          var modals = document.getElementsByClassName('modal');
          for (var i = 0; i < modals.length; i++) {
            modals[i].classList.remove('is-active');
          }
        }
      });
  },
  methods: {
    getClockEventDisplay(event) {
      if (event.type == 'clock_in') {
        return 'In';
      } else {
        return 'Out';
      }
    },
    getClockInOut(visit) {
      if (visit.events.length > 0) {
        let lastEvent = visit.events[visit.events.length - 1];
        if (lastEvent.type == 'clock_in') {
          return 'clock_out';
        } else {
          return 'clock_in';
        }
      } else {
        return 'clock_in';
      }
    },
    suggestClockDateTime(visit) {
      let datetm_str = '';
      if (visit.events.length > 0) {
        let lastEvent = visit.events[visit.events.length - 1];
        if (lastEvent.type == 'clock_in') {
          datetm_str = visit.end_dt_tm.substring()
        }else{
          datetm_str = visit.start_dt_tm;
        }
      } else {
        datetm_str = visit.start_dt_tm;
      }
      return datetm_str
    },
    suggestClockDate(visit) {
      return (this.suggestClockDateTime(visit)).substring(0,10);
    },
    suggestClockTime(visit) {
      return (this.suggestClockDateTime(visit)).substring(11,16);
    },
    async addClockEvent(visit, event_type) {
      this.event_in_progress = true;

      let input = document.getElementById(visit.id+"_date");
      const dateValue = input.value;
      input = document.getElementById(visit.id+"_time");
      const timeValue = input.value;

      this.popup_error = "";
      let eventPackage = {
            "event_type": event_type,
            "event_timestamp": dateValue + ' ' + timeValue + ':00',
        }
      let response = await VisitRequests.visit_clock_auth_request(visit.id, eventPackage);
        if (response.meta.error_code != 0) {
          this.popup_error = response.meta.message;
        } else {
          this.popup_error = "ok";
          for (var i = 0; i < (this.activeVisitList.visits.length); i++) {
            if (this.activeVisitList.visits[i].id == visit.id) {
              this.activeVisitList.visits[i].events = response.data.visit.events;
            }
          }
        }
        this.event_in_progress = false;
    },
    openModal(id) {
      var modal = document.getElementById('visit-'+id+'-modal');
      modal.classList.add('is-active');
    },
    closeModal(id) {
      var modal = document.getElementById('visit-'+id+'-modal');
      modal.classList.remove("is-active");
    },
      
  }
}
</script>

<style scoped>
.row{
  position: relative;
  height: 80px;
  text-align: left;
  border-top: 1px solid #eeeeee;
}

.rowCellBackground {
  position: absolute;
  height: 80px;
  width:120px;
  top:0px;
  background-color: #ffffff;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}

.visit {
  position: absolute;
  height: 80px;
  top:0px;
  background-color: #fcfcfc;
  border: 2px solid #8583b5;
  border-radius: 6px;
  overflow: hidden;
  padding: 4px;
  font-size: small;
}


</style>