<template>
  <div class="box" >
    <table v-if="filter_by=='day'" style="width:100%;">
      <tr>
        <td v-for="day in prev_link_range" :key="day"><button class="button is-small" @click="setFilterValue(day.toISOString().split('T')[0])">{{ formatDate(day) }}</button></td>
        <td><button class="button title">{{ filter_name }}</button></td>
        <td v-for="day in next_link_range" :key="day"><button class="button is-small" @click="setFilterValue(day.toISOString().split('T')[0])">{{ formatDate(day) }}</button></td>
     </tr>
    </table>
    <div v-if="filter_by!='day'" class="title">{{ filter_name }}</div>

    
    <div v-if="group_range.length>0" class="subtitle">{{ group_range }}</div>

    <table class="calendar_whole" :style="'height:'+((visits.length*hour_height)+120)+'px;'">
      <tr>
        <td :style="'width:'+hour_width+'px;'"><SchedulerWideRowHeaders :visits="visits" :group_type="group_by" :hour_height="hour_height" :hour_width="hour_width"/></td>
        <td class="bodyCell">
            <div class="schedule_body" id="schedule-body">
              <SchedulerWideBody :visits="visits" :hour_height="hour_height" :hour_width="hour_width" />
            </div>
        </td></tr>
    </table>
  </div>
    
</template>

<script>
import SchedulerWideRowHeaders from './SchedulerWideRowHeaders.vue';
import SchedulerWideBody from './SchedulerWideBody.vue';
import * as VisitRequests from '../requests/VisitRequests.js'


export default {
  name: 'ScheduleWideBox',
  props: {
    filter_by: {
      type: String,
      required: true,
    },
    filter_value: {
      type: String,
      required: true,
    },
    group_by: {
      type: String,
      required: true,
    },
    group_range_start: {
      type: String,
      required: false,
    },
    group_range_end: {
      type: String,
      required: false,
    },
  },
  components: {
    SchedulerWideRowHeaders,
    SchedulerWideBody
  },
  data() {
    return {
      loading: false,
      hour_width: 120,
      hour_height: 80,
      prev_link_range: [],
      next_link_range: [],
      applied_filter_value: '',
      filter_name: '',
      group_range: '',
      visits: {},
    }
  },
  mounted() {
    this.applied_filter_value = this.filter_value;
    var elmnt = document.getElementById("schedule-body");
    elmnt.scrollLeft += (120*7.5);
    this.loadSchedule();
    this.setPagination();
  },   
  methods: {
    setFilterValue(id) {
      this.applied_filter_value = id;
      this.loadSchedule();
      this.setPagination();
    },
    formatDate(date) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var d = new Date(date);
      return d.getDate() + '-' +monthNames[d.getMonth()];
    },
    months_add(date, months) {
      var tmp_dt = new Date(this.applied_filter_value);
      tmp_dt.setMonth(tmp_dt.getMonth() + months);
      return tmp_dt;
    },
    days_add(date, days) {
      var tmp_dt = new Date(this.applied_filter_value);
      tmp_dt.setDate(tmp_dt.getDate() + days);
      return tmp_dt;
    },
    setPagination() {
      this.prev_link_range = [
        this.months_add(this.applied_filter_value ,-1),
        this.days_add(this.applied_filter_value ,-7),
        this.days_add(this.applied_filter_value ,-6),
        this.days_add(this.applied_filter_value ,-5),
        this.days_add(this.applied_filter_value ,-4),
        this.days_add(this.applied_filter_value ,-3),
        this.days_add(this.applied_filter_value ,-2),
        this.days_add(this.applied_filter_value ,-1),
      ];
      this.next_link_range = [
        this.days_add(this.applied_filter_value ,1),
        this.days_add(this.applied_filter_value ,2),
        this.days_add(this.applied_filter_value ,3),
        this.days_add(this.applied_filter_value ,4),
        this.days_add(this.applied_filter_value ,5),
        this.days_add(this.applied_filter_value ,6),
        this.days_add(this.applied_filter_value ,7),
        this.months_add(this.applied_filter_value ,1),
      ];
    },
    async loadSchedule() {
        let resp = await VisitRequests.visit_schedule_request(this.filter_by, this.applied_filter_value, this.group_by);
        if (resp.data.visits == null){
          this.visits = {};
        }else{
          this.visits = resp.data.visits;
        }
        this.filter_name = resp.data.filter_name;
        this.group_range = '';
        if (resp.data.range_start != null){
          this.group_range = resp.data.range_start;
        }
        if (resp.data.range_end != null){
          this.group_range = this.group_range + ' - ' + resp.data.range_end;
        }
    }
  }
}
</script>

<style scoped>
.schedule_body{
  position: absolute;
  left:0px;
  right:0px;
  overflow-x: scroll;
}

.calendar_whole {
    width:100%;
    margin-top: 10px;
}

.bodyCell {
  position: relative;
}
</style>