import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ScheduleView from '@/views/ScheduleView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/schedule/:filter_by?/:filter_value?/:group_by?/:group_range_start?/:group_range_end?/',
    name: 'schedule',
    component: ScheduleView
  },
  {
    path: '/about/',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/contact/',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/settings/:tab?/:id?/',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
    props: true
  },
  {
    path: '/confirm_account/:user_id?/:code?/',
    name: 'confirm_account',
    component: () => import(/* webpackChunkName: "confirm_account" */ '../views/ConfirmAccountView.vue'),
    props: true
  },
  {
    path: '/logout/',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/LogoutView.vue'),
  },
  {
    path: '/assign-visits/',
    name: 'assign_visits',
    component: () => import(/* webpackChunkName: "assign_visits" */ '../views/AssignVisitsView.vue'),
  },
  {
    path: '/visit/:visit_id?/:event_type?/:confirmation_code?',
    name: 'clock',
    component: () => import(/* webpackChunkName: "clock" */ '../views/ClockView.vue'),
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
